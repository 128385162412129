import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { RequestForDatasetVersionHydraItem } from '@/infrastructure/download-client/download-client'
import { dateToString } from '@/infrastructure/helpers/date-manipulation'
import { useUserStore } from '@/infrastructure/store/user'
import { computed, ComputedRef, defineComponent, onMounted, PropType, reactive, Ref, ref, watch } from '@vue/composition-api'

export interface IDlHistoricVersion{
    hasHistoricVersion:boolean;
    hasDifferenceFiles:boolean;
    hasDiscreteVersions:boolean;
    hasSpecificDiscreteVersions:boolean;
    discreteVersions:RequestForDatasetVersionHydraItem[];
    historicStartDate:Date[];
    historicEndDate:Date[];
    differenceFileMinStartDate:Date[];
    differenceFileMaxStartDate:Date[];
    differenceFileMinEndDate:Date[];
    differenceFileMaxEndDate:Date[];
}

export default defineComponent({
  name: 'dl-historic-versions',
  props: {
    value: {
      type: Object as PropType<IDlHistoricVersion>,
      required: true
    },
    datasetId: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const differenceMaxStartDateMinimum = computed(() => props.value.differenceFileMinStartDate.length > 0 ? props.value.differenceFileMinStartDate[0].toISOString() : null)
    const differenceMaxEndDateMinimum = computed(() => props.value.differenceFileMinEndDate.length > 0 ? props.value.differenceFileMinEndDate[0].toISOString() : null)
    const historicEndDateMinimum : ComputedRef<string|null> = computed(() => props.value.historicStartDate.length > 0 ? props.value.historicStartDate[0].toISOString() : null)
    const historicEndDateMaximum = computed(() => new Date().toISOString())
    const versionRules = computed(() => historicSetup.value.hasDifferenceFiles ? 'minTwo' : 'required')
    const isMounted = ref(false)
    const userStore = useUserStore()
    const isAdmin = computed(() => userStore.isAdmin)

    const discreteVersionOptions: Ref<RequestForDatasetVersionHydraItem[]> = ref([])
    const state: {discreteVersionList:RequestForDatasetVersionHydraItem[]} = reactive({
      discreteVersionList: []
    })
    const historicSetup:Ref<IDlHistoricVersion> = computed({
      get: () => props.value,
      set: (value) => {
        emit('input', value)
      }
    })

    const selectedVersions:Ref<RequestForDatasetVersionHydraItem[]> = computed({
      get: () => {
        return [...new Set(props.value.discreteVersions.map(version => state.discreteVersionList.find(d => d.datasetVersieId === version.datasetVersieId)).filter(x => x !== undefined) as RequestForDatasetVersionHydraItem[])] ?? []
      },
      set: (value) => {
        historicSetup.value.discreteVersions = value
      }
    })

    watch(() => historicSetup.value.hasSpecificDiscreteVersions, () => {
      if (isMounted.value) {
        if (!historicSetup.value.hasSpecificDiscreteVersions) historicSetup.value.discreteVersions = state.discreteVersionList
      }
    }, { deep: true, immediate: true })

    function versionLabel (version:RequestForDatasetVersionHydraItem):string {
      return `${version.omschrijving as string} (${dateToString(version.versieDatum)})`
    }

    function onRemove (removedOption: any, id:any) {
      selectedVersions.value.splice(selectedVersions.value.findIndex(selection => selection.datasetVersieId === removedOption.datasetVersieId), 1)
    }

    onMounted(() => {
      userStore.gettingUserInfo()
      DownloadClientInstance.datasets_GetDatasetVersionsById(props.datasetId).then((response) => {
        state.discreteVersionList = response as RequestForDatasetVersionHydraItem[]
        discreteVersionOptions.value = (response as RequestForDatasetVersionHydraItem[]).sort((a, b) => new Date(a.versieDatum).getTime() - new Date(b.versieDatum).getTime()) ?? []
        if (!props.value.hasSpecificDiscreteVersions) {
          historicSetup.value.discreteVersions = state.discreteVersionList
        }
        isMounted.value = true
      })
    })

    return {
      differenceMaxEndDateMinimum,
      differenceMaxStartDateMinimum,
      historicEndDateMinimum,
      historicEndDateMaximum,
      historicSetup,
      selectedVersions,
      discreteVersionOptions,
      versionRules,
      versionLabel,
      onRemove,
      isAdmin
    }
  }
})
